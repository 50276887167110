<template>
  <section>
    <div class="login_container main_width">
      <div class="left">
        <img src="@/assets/images/common/loginBg.png" alt="" />
      </div>
      <div class="right">
        <div class="content">
          <h2 class="title">手机号登录</h2>
          <el-form ref="formVn" :model="form" :rules="rules">
            <el-form-item prop="mobile">
              <el-input
                class="inputField"
                v-model="form.mobile"
                placeholder="手机号"
                size="medium"
                @input="mobileInputFn"
              ></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <el-input
                class="codeInput"
                v-model="form.code"
                placeholder="手机验证码"
                size="medium"
                @input="codeInputFn"
              ></el-input>
              <el-button
                class="code"
                :class="{ active: isCode }"
                @click="getCode"
                v-if="totalTime === 60"
                >获取验证码</el-button
              >
              <el-button class="code disCode" @click="getCode" disabled v-else
                >{{ totalTime }}s可重发</el-button
              >
            </el-form-item>
          </el-form>
          <div class="confirm" :class="{ active: isConfirm }" @click="confirm">
            登录
          </div>
          <div class="protocol">
            登录即代表您已同意<span @click="showProtocol"
              >《慧点用户协议与隐私政策》</span
            >
          </div>
        </div>
      </div>
    </div>
    <protocol v-model:visible="visible" />
  </section>
</template>

<script lang="ts">
import { sendApplyCode, getUserToken } from '@/api/login'
import { saveToken } from '@/utils/token'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router'
import protocol from './component/protocol.vue'
import { ElMessage } from 'element-plus'
import { defineComponent, onMounted, ref } from 'vue'
export default defineComponent({
  components: {
    protocol
  },
  setup() {
    const route: any = useRoute()
    const router = useRouter()
    const visible = ref<boolean>(false)
    const totalTime = ref<number>(60)
    const isCode = ref<boolean>(false)
    const isConfirm = ref<boolean>(false)
    const formVn: any = ref(null)
    const form = ref({
      mobile: '',
      code: ''
    })
    const rules = ref({
      mobile: [
        {
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            const reg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/
            if (reg.test(value)) {
              callback()
            } else {
              callback(new Error('手机号格式不正确'))
            }
          },
          trigger: ['change', 'blur']
        }
      ],
      code: [
        {
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        },
        {
          min: 4,
          max: 4,
          message: '验证码为4位数',
          trigger: ['change', 'blur']
        }
      ]
    })
    // 获取验证码
    const getCode = () => {
      let num = 0
      formVn.value.validateField(['mobile'], (errorMessage: any) => {
        if (errorMessage === '') {
          num++
          isCode.value = true
        }
      })
      if (num === 1) {
        totalTime.value--
        const timeID = setInterval(() => {
          totalTime.value--
          if (totalTime.value === 0) {
            clearInterval(timeID)
            totalTime.value = 60
          }
        }, 1000)
        sendApplyCode({ mobile: form.value.mobile }).then((res: any) => {
          if (res.status === '1') {
            ElMessage.success({
              message: '短信发送成功',
              type: 'success'
            })
          } else {
            ElMessage.error({
              message: '短信发送失败',
              type: 'error'
            })
          }
        })
      }
    }
    // 提交按钮
    const confirm = () => {
      formVn.value.validate((result: any) => {
        if (result) {
          const params = Object.assign({}, form.value)
          getUserToken(params).then((res: any) => {
            if (res.status === '1') {
              ElMessage.success({
                message: '登录成功',
                type: 'success'
              })
              store.commit('setUserInfo', res.data)
              store.commit('setLogin', true)
              saveToken(res.data.token)
              if (route.query.redirect) {
                router.replace(route.query.redirect)
              } else {
                router.replace('/homePage')
              }
            }
          })
        }
      })
    }
    // 用户协议显示
    const showProtocol = () => {
      visible.value = true
    }
    const mobileInputFn = () => {
      formVn.value.validateField(['mobile'], (errorMessage: any) => {
        if (errorMessage === '') {
          isCode.value = true
        } else {
          isCode.value = false
        }
      })
    }
    const codeInputFn = () => {
      formVn.value.validate((result: any) => {
        if (result) {
          isConfirm.value = true
        } else {
          isConfirm.value = false
        }
      })
    }
    onMounted(() => {
      if (route.query.showTips === '1') {
        ElMessage.error('请先登录')
      }
    })
    return {
      visible,
      totalTime,
      isCode,
      isConfirm,
      formVn,
      rules,
      form,
      getCode,
      confirm,
      showProtocol,
      mobileInputFn,
      codeInputFn
    }
  }
})
</script>

<style lang="scss" scoped>
.login_container {
  display: flex;
  margin-top: 156px;
  margin-bottom: 102px;
  .left,
  .right {
    width: 600px;
    height: 693px;
  }
  .left {
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    padding: 0 140px;
    text-align: center;
    box-shadow: 0px -3px 20px rgba(0, 0, 0, 0.07);
    background-color: #fff;
    border-radius: 10px;
    .content {
      .title {
        font-size: 28px;
        color: #555555;
        margin-top: 114px;
        margin-bottom: 60px;
      }
      .inputField {
        width: 100%;
      }
      .codeInput {
        width: 60%;
        text-align: left;
      }
      .code {
        width: 35%;
        height: 36px;
        background: #fff4de;
        opacity: 0.5;
        border-radius: 4px;
        margin-left: 5%;
        color: #c8a260;
        font-size: 12px;
        border: 0;
        cursor: pointer;
      }
      .active {
        opacity: 1 !important;
      }
      .disCode {
        background: #f9f9f9;
        color: #bbbbbb;
      }
      .confirm {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50px;
        background: linear-gradient(90deg, #f4dcb4 0%, #e9bb6b 100%);
        opacity: 0.5;
        border-radius: 100px;
        color: #000;
        font-size: 18px;
        margin-top: 38px;
        cursor: pointer;
      }
      .protocol {
        font-size: 14px;
        color: #999999;
        margin-top: 50px;
        span {
          color: #3c89ff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
