
import { sendApplyCode, getUserToken } from '@/api/login'
import { saveToken } from '@/utils/token'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router'
import protocol from './component/protocol.vue'
import { ElMessage } from 'element-plus'
import { defineComponent, onMounted, ref } from 'vue'
export default defineComponent({
  components: {
    protocol
  },
  setup() {
    const route: any = useRoute()
    const router = useRouter()
    const visible = ref<boolean>(false)
    const totalTime = ref<number>(60)
    const isCode = ref<boolean>(false)
    const isConfirm = ref<boolean>(false)
    const formVn: any = ref(null)
    const form = ref({
      mobile: '',
      code: ''
    })
    const rules = ref({
      mobile: [
        {
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            const reg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/
            if (reg.test(value)) {
              callback()
            } else {
              callback(new Error('手机号格式不正确'))
            }
          },
          trigger: ['change', 'blur']
        }
      ],
      code: [
        {
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        },
        {
          min: 4,
          max: 4,
          message: '验证码为4位数',
          trigger: ['change', 'blur']
        }
      ]
    })
    // 获取验证码
    const getCode = () => {
      let num = 0
      formVn.value.validateField(['mobile'], (errorMessage: any) => {
        if (errorMessage === '') {
          num++
          isCode.value = true
        }
      })
      if (num === 1) {
        totalTime.value--
        const timeID = setInterval(() => {
          totalTime.value--
          if (totalTime.value === 0) {
            clearInterval(timeID)
            totalTime.value = 60
          }
        }, 1000)
        sendApplyCode({ mobile: form.value.mobile }).then((res: any) => {
          if (res.status === '1') {
            ElMessage.success({
              message: '短信发送成功',
              type: 'success'
            })
          } else {
            ElMessage.error({
              message: '短信发送失败',
              type: 'error'
            })
          }
        })
      }
    }
    // 提交按钮
    const confirm = () => {
      formVn.value.validate((result: any) => {
        if (result) {
          const params = Object.assign({}, form.value)
          getUserToken(params).then((res: any) => {
            if (res.status === '1') {
              ElMessage.success({
                message: '登录成功',
                type: 'success'
              })
              store.commit('setUserInfo', res.data)
              store.commit('setLogin', true)
              saveToken(res.data.token)
              if (route.query.redirect) {
                router.replace(route.query.redirect)
              } else {
                router.replace('/homePage')
              }
            }
          })
        }
      })
    }
    // 用户协议显示
    const showProtocol = () => {
      visible.value = true
    }
    const mobileInputFn = () => {
      formVn.value.validateField(['mobile'], (errorMessage: any) => {
        if (errorMessage === '') {
          isCode.value = true
        } else {
          isCode.value = false
        }
      })
    }
    const codeInputFn = () => {
      formVn.value.validate((result: any) => {
        if (result) {
          isConfirm.value = true
        } else {
          isConfirm.value = false
        }
      })
    }
    onMounted(() => {
      if (route.query.showTips === '1') {
        ElMessage.error('请先登录')
      }
    })
    return {
      visible,
      totalTime,
      isCode,
      isConfirm,
      formVn,
      rules,
      form,
      getCode,
      confirm,
      showProtocol,
      mobileInputFn,
      codeInputFn
    }
  }
})
