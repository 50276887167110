<template>
  <el-dialog
    width="800px"
    v-model="isShow"
    @close="close"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="protocol">
      <div class="title">慧点用户协议与隐私政策</div>
      <div class="content">
        <h2>一、概述</h2>
        <p class="proxy_zw">
          本用户授权协议（以下简称“本协议”）是九慧数字科技有限公司（以下简称“本公司”）与用户（以下或称为“您”）就企业慧点平台服务（以下简称“本服务”）的授权事宜所订立的有效合约。
        </p>
        <p class="proxy_zw">
          您勾选“本人已认真阅读并同意《用户授权协议》”，即表示您与本公司已达成并签署本协议且您无条件同意接受本协议的全部约定内容。
        </p>
        <p class="proxy_zw">
          本公司提醒您认真阅读、充分理解本规则各条款，特别是其中与您的权益有或可能具有重大关系的条款，及对本公司具有或可能具有免责或限制责任的条款（尤其是
          粗体标注部分）。
          如您无法准确理解相关条款含义，请不要进行后续操作。如您不同意接受本协议的任意内容，将无法进行后续操作。
        </p>
        <p class="proxy_zw">
          如果您对本协议的条款有疑问的，请拨打客服电话（0755-23984329）进行询问，本公司将向您解释条款内容。
        </p>

        <h2>二、定义</h2>
        <p class="proxy_zw">
          2.1
          企业慧点平台：指由本公司开发并运营的提供平台服务、信息展示等服务的平台。
        </p>
        <p class="proxy_zw">
          2.2
          金融机构：指在企业慧点平台发布产品信息，经本公司撮合，向在企业慧点平台提出金融服务申请的用户提供服务的法人。
        </p>
        <p class="proxy_zw">
          2.3
          用户：指通过企业慧点平台使用企业服务的自然人、法人或其他组织，企业服务包括但不限于投融资服务及其他增值服务。
        </p>

        <h2>三、授权条款</h2>
        <p class="proxy_zw">
          3.1
          您授权本公司在您使用本服务期间或本服务终止后，基于为更好的向您提供服务之目的或向您推广其他产品/服务的需要，有权保留并使用您在使用本服务期间所形成的相关信息数据（包括您选择的金融机构合法收集、保存的您的相关信息），并提供给本公司及其关联公司或本公司合作的金融机构、服务企业、监管机构等。
        </p>
        <p class="proxy_zw">
          3.2
          您授权本公司及其关联公司采集、查询、验证、使用您的信息（包括但不限于姓名、身份证号码、手机号等，以及关联企业组织的工商信息、经营信息等），并将您的全部/部分信息以及该等信息使用情况向您选择的金融机构提供，该等信息的提供以金融机构为提供服务所需。
        </p>
        <p class="proxy_zw">
          3.3
          本公司提供相应页面，您可在页面输入用户名、密码等方式登录相应查询网址，查询包括但不限于您的公积金数据、电信运营商数据等，以及关联企业组织的工商数据、经营数据等，您同意并授权本公司可以获取您查询的相应信息，并将上述信息及信息使用情况传输给您所申请金融服务的金融机构。
        </p>
        <p class="proxy_zw">3.4 您同意本协议中的授权不可变更、撤回或撤销。</p>
        <h2>四、其他条款</h2>
        <p class="proxy_zw">
          4.1
          您同意，本公司有权在必要时修改本协议。本协议一旦发生变动，本公司将会在首页上提示修改内容或通过其他形式告知您。如果您不同意所改动的内容，应当立即停止使用本平台相关服务。如果您继续使用本平台服务，则视为接受本协议的变动。本公司通过平台发布的通知、公告、声明或其他类似的内容是本协议的一部分。平台所发布的通知、公告、声明或其他类似的内容不得损害平台用户的合法权益。
        </p>
        <p class="proxy_zw">
          4.2双方在履行本协议的过程中，如发生争议，应协商解决。协商不成的，任何一方均应向深圳仲裁委员会申请仲裁解决。
        </p>
      </div>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ct) {
    const isShow = computed({
      get() {
        return props.visible
      },
      set(v) {
        ct.emit('update:visible', v)
      }
    })
    const close = () => {
      isShow.value = false
    }
    return {
      isShow,
      close
    }
  }
})
</script>

<style lang="scss" scoped>
.protocol {
  padding: 0 150px;
  .title {
    font-size: 20px;
  }
  .content {
    width: 100%;
    height: 402px;
    padding: 10px;
    border: 1px solid #eaeaea;
    font-size: 16px;
    font-family: Source Han Sans CN;
    line-height: 27px;
    color: #777777;
    overflow-y: auto;
    text-align: left;
    margin-top: 26px;
    h2 {
      font-size: 20px;
      line-height: 40px;
    }
    .proxy_zw {
      font-size: 16px;
      line-height: 30px;
      text-indent: 2em;
    }
  }
}
</style>
